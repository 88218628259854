<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}

/deep/ .c-panel .el-form .el-input {
  width: 350px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '通道增加' : '通道数据修改'" :visible.sync="isShow" width="650px"
             :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog"
             :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form :model="m" size="small" :inline="true" :rules="rules" label-width="150px" ref="dynamicValidateForm">
          <el-form-item label="对外通道类型">
            <el-radio-group v-model="m.payType">
              <el-radio label="alipay">支付宝</el-radio>
              <el-radio label="wxpay">微信</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="自定义通道类型:">
            <el-input type="text" v-model="m.payType" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="内部通道编码:" prop="sysType">
            <el-input-number @change="m.couponSwitch=0" v-model="m.sysType" style="width: 350px"></el-input-number>
            <el-select v-model="m.sysType" placeholder="请选择内部通道"
                       style="display: block; width: 100%;">
              <el-option @change="m.couponSwitch=0" v-for="item in channelList" :key="item.channelNumber"
                         :label="item.channelName"
                         :value="item.channelNumber">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="checkChannel" label="是否启用优惠券:">
            <el-switch
              v-model="m.couponSwitch"
              :active-value="1"
              :inactive-value="0"
              active-text="开启"
              inactive-text="关闭">
            </el-switch>
          </el-form-item>
          <el-form-item label="限额:" prop="limits">
            <el-input type="text" v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="最小金额:" prop="min">
            <el-input type="text" v-model="m.min" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="最大金额:" prop="max">
            <el-input type="text" v-model="m.max" style="width: 350px"></el-input>
          </el-form-item>

          <el-form-item label="调用商户:" prop="mchIds">
            <el-select v-model="m.mchIds" multiple placeholder="请选择使用此通道的商户"
                       style="display: block; width: 100%;">
              <el-option v-for=" item in mchlists" :key="item.mchId" :label="item.merchantNickName" :value="item.mchId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="码队权重:" prop="pollingStatus">
            <el-select v-model="m.pollingStatus">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
            <el-button v-show="m.pollingStatus === 1" icon="el-icon-plus" size="mini" style="margin-left: 5px;"
                       @click="addDomain"></el-button>
          </el-form-item>

          <el-form-item v-for="(domain, index) in m.pollingRule" v-show="m.pollingStatus === 1" :label="'权重' + index"
                        :key="domain.key" :prop="'pollingRule.' + index + '.value'">
            <el-select v-model="domain.mchId" placeholder="请选择码队" style="display: block; width: 80%;">
              <el-option v-for=" item in codeMchlists" :key="item.mchId" :label="item.merchantNickName"
                         :value="item.mchId">
              </el-option>
            </el-select>
            <el-input type="text" v-model="domain.weight" placeholder="轮询次数" style="width: 350px"></el-input>
            <el-button icon="el-icon-minus" @click.prevent="removeDomain(domain)" style="margin-left: 5px;"></el-button>
          </el-form-item>

          <el-form-item label="备注:">
            <el-input type="text" v-model="m.remarks" style="width: 350px"></el-input>
            <br />
          </el-form-item>
          <br />
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    var checkPollingStatus = (rule, value, callback) => {
      if (this.m.pollingStatus === 0) {
        return callback()
      }
      if (this.m.pollingRule == null || this.m.pollingRule.length === 0) {
        return callback(new Error('请添加权重规则'))
      }
      callback()
    }
    return {
      //优惠券通道
      couponChannel: [7, 25, 26, 17, 16, 12],
      isShow: false,
      id: 0,
      mchlists: [],
      codeMchlists: [],
      m: {
        payType: 'alipay',
        sysType: 7,
        pollingStatus: 0,
        limits: '',
        min: 1,
        max: 500,
        type: '',
        mchIds: [],
        pollingRule: [{
          mchId: null,
          weight: null,
        }],
        status: 0,
        remarks: '',
        couponSwitch: 0,
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
      channelList: [],
      rules: {
        sysType: [
          { required: true, message: '请输入自定义通道类型', trigger: 'change' },
        ],
        limits: [
          { required: true, message: '请输入限额', trigger: 'blur' },
        ],
        min: [
          { required: true, message: '请输入最小金额', trigger: 'blur' },
        ],
        max: [
          { required: true, message: '请输入最大金额', trigger: 'blur' },
        ], mchIds: [
          { required: true, message: '请选择调用商户', trigger: 'change' },
        ],
        pollingStatus: [
          { validator: checkPollingStatus, trigger: 'change' },
        ],
      },

    }
  },
  computed: {
    checkChannel: {
      get () {return this.couponChannel?.includes(this.m.sysType)},
    },
  },
  methods: {
    // 打开
    open: function (data, type) {
      this.id = data.id
      this.isShow = true
      if (this.id == 0 || data == 0) {
        // 如果是添加
        console.log(this.m)
        this.id = 0
        this.m.type = type
      } else {
        this.m.payType = data.payType
        this.m.sysType = data.sysType
        this.m.status = data.status
        this.m.remarks = data.remarks
        this.m.limits = data.limits
        this.m.mchIds = data.mchIds
        this.m.min = data.min
        this.m.max = data.max
        this.m.pollingStatus = data.pollingStatus
        this.m.couponSwitch = data.couponSwitch
        if (data.pollingRule != null) {
          this.m.pollingRule = data.pollingRule
        }
      }
    },

    // 提交
    ok: function () {
      // 表单验证
      this.$refs['dynamicValidateForm'].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.addOrUpdate()
        } else {
          console.log('error submit!!')
          return false
        }
      })

    },
    addOrUpdate () {
      // 开始增加或修改
      let self = this
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      if (this.id == 0) {
        this.sa.ajax(
          '/v1/sifang/save',
          this.m,
          function () {
            self.sa.alert('增加成功', function () {
              self.$parent.f5() // 父视图刷新
              self.isShow = false
              self.claerData()
            })
          },
          defaultCfg,
        )
      } else {
        // id != 0 为修改
        this.sa.ajax(
          '/v1/sifang/edit/' + this.id,
          this.m,
          function () {
            self.sa.alert(
              '修改成功',
              function () {
                self.$parent.f5() // 父视图刷新
                self.isShow = false
                self.claerData()
              },
              defaultCfg,
            )
          },
          defaultCfg,
        )
      }
    },
    claerData () {
      this.m.status = 0
      this.m.pollingType = 0
      this.m.remarks = ''
      this.m.payType = ''
      this.m.sysType = 7
      this.m.mchIds = []
      this.m.pollingRule = []
      this.m.couponSwitch = 0
      this.m.pollingStatus = 0
    },
    addDomain () {
      this.m.pollingRule.push({
        mchId: null,
        weight: null,
      })
      this.addCheckPollingRule(this.m.pollingRule.length - 1)
    },
    removeDomain (item) {
      var index = this.m.pollingRule.indexOf(item)
      if (index !== -1) {
        this.m.pollingRule.splice(index, 1)
      }
    },
    getMchName () {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
      }
      this.sa.ajax(
        '/v2/admin/queryMerchantNickName/1',
        function (res) {
          this.mchlists = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    getCodeMchName () {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
      }
      this.sa.ajax(
        '/v2/admin/queryMerchantNickName/3',
        function (res) {
          this.codeMchlists = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    getChannelList () {
      var defaultCfg = {
        type: 'get', //是否是请求体请求
      }
      this.sa.ajax(
        '/v1/channelmg/simple-list',
        function (res) {
          this.channelList = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    addCheckPollingRule (index) {
      this.$set(this.rules, `pollingRule.${index}.value`, [
        { validator: this.checkPollingRule, trigger: 'blur' },
      ])
    },
    checkPollingRule (rule, value, callback) {
      const index = rule.field.split('.')[1]
      if (this.m.pollingStatus === 0) {
        return callback()
      }
      if (this.m.pollingRule == null || this.m.pollingRule.length === 0) {
        return callback(new Error('请添加权重规则'))
      }
      const item = this.m.pollingRule[index]

      if (item.mchId == null || item.mchId === '') {
        return callback(new Error('请选择码队'))
      }
      if (item.weight == null || item.weight === '') {
        return callback(new Error('请输入轮询次数'))
      }
      return callback()
    },
  },
  created: function () {
    this.getMchName()
    this.getCodeMchName()
    this.addCheckPollingRule(this.m.pollingRule.length - 1)
    this.getChannelList()
  },
}
</script>
