<style>
/deep/ .c-panel .el-form .el-input {
  width: 800px;
}
</style>
<template>
  <div>
    <el-form size="medium" :inline="true">
      <el-form-item label="选择码队：">
        <el-select v-model="select" style="display: block; width: 180%" @change="datas" placeholder="请选择" multiple>
          <el-option style="display: block; width: 180%;" v-for="item in lists" :key="item.mchId" :label="item.merchantNickName" :value="item.mchId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    select: {
      default: ""
    }
  },
  data() {
    return {
      m: {
        roleId: "3",
        page: 1,
        limit: 100,
      },
      mchId: "",
      selectMchIds: [],
      lists: [],
    };
  },
  methods: {
    f5: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        msg: null,
      };
      this.sa.ajax(
        "/v2/admin/queryMerchantNickName/" + this.m.roleId,
        function (res) {
          this.lists = res.data; // 数据
          this.selectMchIds = this.select;
        }.bind(this),
        defaultCfg
      );
    },
    datas(val) {
      this.$emit("mchId", val);
    },
  },
  created: function () {
    this.f5();
  },
};
</script>
